.CollapsedMenuHeader___3Xq0k {
  height: 44px;
  padding: 10px 16px;
}
.CollapsedMenuHeader___3Xq0k .ant-dropdown {
  left: auto !important;
  right: 0 !important;
  width: 100%;
}
.DropdownMenu___1COw- {
  position: fixed;
  left: auto !important;
  right: 0 !important;
  width: 100%;
  top: 44px !important;
  bottom: 0;
  background: #ffffff;
}
.CollapsedMenu___2w6pD {
  max-height: 100%;
  overflow-y: auto;
  background: #ffffff;
}
.CollapsedMenu___2w6pD .inner {
  padding: 16px;
}
.CollapsedMenu___2w6pD .ant-menu-inline {
  border-right: none;
}
.CollapsedMenu___2w6pD .align-right {
  text-align: right;
}
.switchLocalButton___3t39i {
  background-color: #F5F5F5 !important;
  border: 0;
  color: #92929F !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}
.switchLocalButton___3t39i span {
  margin-top: 4px;
  line-height: 1;
}
