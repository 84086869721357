.FloatLabelComponent___1uJOr {
  position: relative;
}
.FloatLabelComponent___1uJOr .label {
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
}
.FloatLabelComponent___1uJOr .ant-input-group-addon {
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.FloatLabelComponent___1uJOr .ant-input-group-wrapper-lg + .label {
  left: 58px;
}
.FloatLabelComponent___1uJOr .as-placeholder {
  color: #c0c2cc;
}
.FloatLabelComponent___1uJOr .as-label {
  top: -10px;
  font-size: 14px !important;
  background: white;
  padding: 0 4px;
}
.FloatLabelComponent___1uJOr .ant-input-group-wrapper-lg + .as-label {
  left: 52px;
}
.FloatLabelComponent___1uJOr .ant-input-lg {
  padding: 3px 10px;
  height: auto;
  font-size: 16px;
  line-height: 36px;
  border-radius: 6px;
}
.FloatLabelComponent___1uJOr .ant-select-lg {
  border-radius: 6px;
}
.FloatLabelComponent___1uJOr .ant-select-lg .ant-select-selector {
  padding: 3px 10px !important;
  font-size: 16px;
  line-height: 36px;
  height: auto !important;
}
.FloatLabelComponent___1uJOr .ant-select-lg .ant-select-item-option-content {
  font-size: 16px;
  line-height: 36px;
  padding: 6px 0;
}
