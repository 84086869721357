/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.Layout___3dqqo {
  min-height: 100vh;
  background: #ffffff;
  padding-top: 72px;
  font-family: 'Proxima Nova', 'PingFang SC';
}
@media (max-width: 992px) {
  .Layout___3dqqo {
    padding-top: 44px;
  }
}
.Layout___3dqqo .Content___147T1 {
  padding: 100px 64px 0;
}
@media (max-width: 992px) {
  .Layout___3dqqo .Content___147T1 {
    padding: 44px 16px 0;
  }
}
.Layout___3dqqo .Content___147T1 .text-center {
  text-align: center;
}
.Layout___3dqqo .Content___147T1 .common-section {
  margin-bottom: 162px;
}
@media (max-width: 575px) {
  .Layout___3dqqo .Content___147T1 .common-section {
    margin-bottom: 36px;
  }
}
.Layout___3dqqo .Content___147T1 .section-title {
  font-size: 42px;
  line-height: 56px;
  margin-bottom: 64px;
  font-family: 'Proxima Nova', 'PingFang SC';
}
@media (max-width: 575px) {
  .Layout___3dqqo .Content___147T1 .section-title {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 36px;
  }
}
.Layout___3dqqo .Content___147T1 .section-sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #92929F;
}
@media (max-width: 575px) {
  .Layout___3dqqo .Content___147T1 .section-sub-title {
    font-size: 20px;
    line-height: 30px;
  }
}
.Layout___3dqqo .Content___147T1 .common-title {
  font-size: 28px;
  line-height: 24px;
  color: #1d1b2e;
}
@media (max-width: 575px) {
  .Layout___3dqqo .Content___147T1 .common-title {
    font-size: 24px;
    line-height: 24px;
  }
}
.Layout___3dqqo .Content___147T1 h1 {
  font-size: 48px;
  line-height: 64px;
  color: #1d1b2e;
  font-family: 'Proxima Nova', 'PingFang SC';
  margin: 0;
}
@media (max-width: 575px) {
  .Layout___3dqqo .Content___147T1 h1 {
    font-size: 28px;
    line-height: 36px;
  }
}
.Layout___3dqqo .Content___147T1 h2 {
  font-size: 28px;
  line-height: 32px;
  color: #1d1b2e;
  font-family: 'Proxima Nova', 'PingFang SC';
  margin: 0;
}
.Layout___3dqqo .Content___147T1 h3 {
  font-size: 24px;
  line-height: 32px;
  color: #1d1b2e;
  font-family: 'Proxima Nova', 'PingFang SC';
  margin: 0;
}
.Layout___3dqqo .Content___147T1 h4 {
  font-size: 18px;
  line-height: 30px;
  color: #1d1b2e;
  font-family: 'Proxima Nova', 'PingFang SC';
  margin: 0;
}
.Layout___3dqqo .Content___147T1 p {
  font-size: 18px;
  line-height: 30px;
  color: #92929f;
}
@media (max-width: 575px) {
  .Layout___3dqqo .Content___147T1 p {
    font-size: 14px;
    line-height: 22px;
  }
}
.Layout___3dqqo p,
.Layout___3dqqo .ant-typography {
  color: #1d1b2e;
  padding: 0;
  margin: 0;
}
.Layout___3dqqo .container {
  max-width: max(90vw,1440px);
  margin: 0 auto;
}
.pos-web-site-home-page .ant-layout-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pos-web-site-home-page .section-content {
  padding-left: 64px;
  padding-right: 64px;
}
@media (max-width: 992px) {
  .pos-web-site-home-page .section-content {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
.full-width-page .ant-layout-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.full-width-page .section-content {
  padding-left: 64px;
  padding-right: 64px;
}
@media (max-width: 992px) {
  .full-width-page .section-content {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
