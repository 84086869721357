/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.step1WithReferralIntroduction .ant-form-item {
  margin-bottom: 24px;
}
.step1WithReferralIntroduction .ant-btn-primary[disabled] {
  background-color: rgba(206, 44, 84, 0.5);
  color: #fff;
}
.step1WithReferralIntroduction .referral-introduction p {
  margin-bottom: 24px;
  margin-top: 24px;
}
.step1WithReferralIntroduction .referral-introduction p a {
  text-decoration: underline;
  color: #92929F;
}
.step1WithReferralIntroduction .referral-introduction .referral-title {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
  color: #1D1B2E;
}
@media (max-width: 575px) {
  .step1WithReferralIntroduction .referral-introduction .referral-title {
    font-size: 31px;
  }
}
.step1WithReferralIntroduction .referral-introduction .referral-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #92929F;
  margin-top: 16px;
}
@media (max-width: 575px) {
  .step1WithReferralIntroduction .referral-introduction .referral-subtitle {
    font-size: 16px;
  }
}
.step1WithReferralIntroduction .referral-introduction .referral-title-emphase {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #E00051;
  margin-top: 32px;
  margin-bottom: 16px;
}
@media (max-width: 575px) {
  .step1WithReferralIntroduction .referral-introduction .referral-title-emphase {
    font-size: 21px;
  }
}
.step1WithReferralIntroduction .referral-introduction .referral-sucess-point {
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #92929F;
  text-align: left;
  margin: 10px 0;
}
@media (max-width: 575px) {
  .step1WithReferralIntroduction .referral-introduction .referral-sucess-point {
    font-size: 14px;
    margin: 5px 0;
  }
}
.StepContainer___WPC6o {
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  background-color: #ffffff;
  padding-bottom: 20px;
  padding-top: 20px;
}
.StepContainer___WPC6o .pos-poster {
  width: 640px;
  margin: 0 32px 0 0;
  width: 47%;
}
.StepContainer___WPC6o .pos-poster.referral-introduction {
  margin-right: 44px;
}
.StepContainer___WPC6o .pos-poster .client-3-in-1-image {
  cursor: pointer;
  width: 75%;
  object-fit: contain;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-poster .client-3-in-1-image {
    width: 100%;
  }
}
.StepContainer___WPC6o .pos-poster .client-3-in-1-image-2 {
  cursor: pointer;
  width: 90%;
  object-fit: contain;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-poster .client-3-in-1-image-2 {
    width: 100%;
  }
}
.StepContainer___WPC6o .demo-instroduction-section {
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .demo-instroduction-section {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.StepContainer___WPC6o .demo-instroduction-section .demo-instroduction-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: normal;
  color: #E00051;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .demo-instroduction-section .demo-instroduction-title {
    font-size: 25px;
  }
}
.StepContainer___WPC6o .demo-instroduction-section .demo-instroduction-items {
  display: block;
}
.StepContainer___WPC6o .demo-instroduction-section .keypoint-list {
  width: 100%;
  padding-inline-start: 20px;
  font-family: 'Proxima Nova', 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #1D1B2E;
  margin-bottom: 0;
}
.StepContainer___WPC6o .demo-instroduction-section .keypoint-list .keypoint-item {
  width: 100%;
  margin: 10px 0 ;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: normal;
  color: #92929F;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .demo-instroduction-section .keypoint-list {
    font-size: 14px;
  }
  .StepContainer___WPC6o .demo-instroduction-section .keypoint-list .keypoint-item {
    width: 100%;
    margin: 5px 0 ;
  }
}
.StepContainer___WPC6o .pos-description {
  flex: 1;
  min-width: 0;
}
.StepContainer___WPC6o .pos-description .pos-description-inner {
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 42px 34px 42px 34px;
  position: sticky;
  top: 71px;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description .pos-description-inner {
    padding: 20px 20px 20px 20px;
  }
}
.StepContainer___WPC6o .pos-description .action-button {
  width: 100%;
  height: 60px;
  padding: 8px 0;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  border-radius: 28px;
  font-weight: 600;
}
.StepContainer___WPC6o .pos-description .actionSubTitle {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: #4e5260 !important;
  margin-top: 10px;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description .actionSubTitle {
    font-size: 12px;
  }
}
.StepContainer___WPC6o .pos-description h1 {
  font-size: 42px;
}
.StepContainer___WPC6o .pos-description .top-emphasis-description .emphasis-header,
.StepContainer___WPC6o .pos-description .top-emphasis-description h1 {
  font-size: 34px;
  line-height: 42px;
  color: #E00051;
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 600;
  font-family: 'Proxima Nova', 'PingFang SC';
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description .top-emphasis-description .emphasis-header,
  .StepContainer___WPC6o .pos-description .top-emphasis-description h1 {
    font-size: 23px;
    line-height: 36px;
  }
}
.StepContainer___WPC6o .pos-description .top-emphasis-description p {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #1D1B2E;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description .top-emphasis-description p {
    font-size: 14px;
    line-height: 22px;
  }
}
.StepContainer___WPC6o .pos-description .top-emphasis-description p.withCheckbox {
  display: flex;
  align-items: center;
  line-height: 16px;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description h1 {
    font-size: 25px;
    line-height: 36px;
  }
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description .action-button {
    margin-top: 20px;
    flex: 1;
    font-size: 18px;
    line-height: normal;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
.StepContainer___WPC6o .pos-description.withintroduction {
  position: relative;
  border-radius: 24px;
  background-color: #F5F5F5;
  padding: 24px;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o .pos-description.withintroduction {
    padding: 24px 10px 10px 10px;
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .StepContainer___WPC6o .pos-poster {
    width: 100% !important;
    margin: 0 0 16px 0;
  }
  .StepContainer___WPC6o .pos-description {
    flex: 100%;
  }
}
@media (max-width: 414px) {
  .StepContainer___WPC6o .label.as-placeholder {
    font-size: 13px;
    top: 13px;
  }
}
.StepContainer___WPC6o .labelRequired___21iFI::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  position: absolute;
  top: 3px;
  right: -12px;
}
.StepContainer___WPC6o.StepContainerForHome___3W4wX {
  background-color: #ffffff;
  border-radius: 16px;
}
.StepContainer___WPC6o.StepContainerForHome___3W4wX .pos-poster {
  padding: 42px 0px 42px 0px;
}
@media (max-width: 575px) {
  .StepContainer___WPC6o.StepContainerForHome___3W4wX .pos-poster {
    padding: 0px 0px 0px 0px;
  }
}
@media (max-width: 575px) {
  .StepContainer___WPC6o {
    flex-wrap: wrap-reverse;
    position: static;
    top: unset;
    z-index: 10;
    background-color: #ffffff;
    padding-bottom: unset;
  }
}
.ClientLogoWallet___2rk1B {
  margin-top: 20px;
}
.ClientLogoWallet___2rk1B .header___1M1x7 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin-bottom: 24px;
  background-color: #ce2c54;
  margin-left: 20px;
  margin-right: 20px;
  color: #ffffff;
  line-height: normal;
  padding: 20px 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV {
  position: relative;
  background-color: #ce2c54;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV::before {
  height: 2px;
  background-color: #ffffff;
  min-width: 10px;
  width: 10px;
  flex: 1;
  max-width: 20px;
}
.ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV span {
  background-color: #ce2c54;
  padding: 0 5px;
  flex: 9;
}
.ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV::after {
  height: 2px;
  background-color: #ffffff;
  min-width: 10px;
  width: 10px;
  flex: 1;
  max-width: 20px;
}
.ClientLogoWallet___2rk1B .header___1M1x7::before {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 100%;
  margin-right: 5px;
}
.ClientLogoWallet___2rk1B .header___1M1x7::after {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 100%;
  margin-left: 5px;
}
@media (max-width: 1200px) {
  .ClientLogoWallet___2rk1B .header___1M1x7 {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575px) {
  .ClientLogoWallet___2rk1B .header___1M1x7 {
    padding: 10px;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .ClientLogoWallet___2rk1B .header___1M1x7::before {
    display: none;
  }
  .ClientLogoWallet___2rk1B .header___1M1x7::after {
    display: none;
  }
  .ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV {
    padding: 0 5px;
  }
  .ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV::before {
    display: none;
  }
  .ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV::after {
    display: none;
  }
  .ClientLogoWallet___2rk1B .header___1M1x7 .headerTitle___1krbV span {
    padding: 0;
    font-size: 18px;
  }
}
@media (min-width: 1600px) {
  .ClientLogoWallet___2rk1B .ant-col-xxl-5 {
    flex-basis: 20%;
  }
}
@media (min-width: 992px) {
  .ClientLogoWallet___2rk1B .ant-col-xxl-5 {
    flex-basis: 20%;
  }
}
@media (min-width: 768px) {
  .ClientLogoWallet___2rk1B .ant-col-xxl-5 {
    flex-basis: 20%;
  }
}
.ClientLogoWallet___2rk1B .client-logo-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ClientLogoWallet___2rk1B .client-logo-container .client-logo-image {
  cursor: pointer;
  width: 50%;
  object-fit: contain;
}
.closeIconOnAsModal___3zxFe {
  width: 30px;
  height: 30px;
  background-image: url('https://pos-static.chowbus.com/assets/dialog-close.png');
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  border: 1px solid #cccccc;
  position: absolute;
  right: -8px;
  top: -8px;
  z-index: 1;
  background-color: #ffffff;
  cursor: pointer;
}
