/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ExpandMenuHeader___15POV {
  height: 72px;
  padding: 12px 0px;
  border-bottom: 1px solid #f5f5f5;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 64px;
  padding-right: 64px;
}
@media (max-width: 414px) {
  .ExpandMenuHeader___15POV {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
@media (max-width: 1439px) {
  .ExpandMenuHeader___15POV {
    padding: 12px 64px;
  }
}
.ExpandMenu___356zE {
  border-bottom: none;
}
.ExpandMenu___356zE .ant-menu-item,
.ExpandMenu___356zE .ant-menu-submenu-horizontal {
  padding: 0 !important;
  margin: 0 16px !important;
}
.switchLocalButton___RZ0Zv {
  background-color: #F5F5F5;
  border: 0;
  color: #92929F;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
}
.switchLocalButton___RZ0Zv span {
  padding-top: 4px;
  line-height: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.switchLocalButton___RZ0Zv:hover {
  background-color: #ce2c54;
  color: #ffffff;
}
