.frozen_ratio___3CTgu {
  width: 100%;
  position: relative;
}
.frozen_ratio___3CTgu > .holder___3YVhN {
  width: 100%;
  padding-top: 100%;
  background-color: transparent;
  display: block;
}
.frozen_ratio___3CTgu > .main_content___1k0sE {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
